<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="../solution/img/顶部背景.png" alt="" />
    </div>
    <div class="bottomCss">
      <div class="xiangqing">
        <div class="title">微电网解决方案</div>
        <div class="jieshao">
          公司依托自身在微电网领域十多年的技术基础及行业应用，针对产业园区、高校、工业企业、偏远地区等地区，提供微电网整体解决方案，包括方案的设计、设备采购、工程服务、运行维护的一站式EPC服务，实现微型、清洁、友好、自治的微电网。
        </div>
        <div class="xiaobiaoqian">
          <img src="./img/小标签.png" alt="" />
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >方案特点</span
          >
        </div>
        <div class="jieshao">
          结合市场需求及资源条件，以新能源发电为基础，多种能源互补的只能微电网，既可并网运行，也能离网运行。
          提供一站式EPC服务，通过智能微电网控制系统，有效对能源进行优化调度，确保用能稳定性和可靠性。
        </div>
        <div>
          <img
            style="width: 1186px; margin-top: 36px"
            src="./img/微电网1.png"
            alt=""
          />
        </div>
        <div class="xiaobiaoqian">
          <img src="./img/小标签.png" alt="" />
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >方案架构</span
          >
        </div>
        <div>
          <img
            style="width: 1186px; margin-top: 36px; margin-bottom: 64px"
            src="./img/微电网2.png"
            alt=""
          />
        </div>
        <div class="xiaobiaoqian9">
          <img src="./img/小标签.png" alt="" />
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >微电网能量管理系统</span
          >
        </div>
        <div>
          <img
            style="width: 1186px; margin-top: 36px; margin-bottom: 64px"
            src="./img/微电网3.png"
            alt=""
          />
        </div>
        <div class="xiaobiaoqian">
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >系统功能</span
          >
        </div>
        <div style="width: 89%;margin-bottom:64px">
          <ul
            v-for="(item, index) in dataBox"
            :key="index"
          >
            <li
              style="
                font-size: 20px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                display: flex;
                margin-top: 24px;
              "
            >
              {{ item.text }}
            </li>
          </ul>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataBox: [
          {
            text: "实时数据采集与处理（采集数据，滤除无效数据，并同时告警）",
          },
          {
            text: "运营监视与告警（监视各分布式电源运行状态，微网系统和各运行设备的实际运行工况信息）",
          },
          {
            text: "控制与调节（可实现预定义控制序列实现可靠闭锁，以改变输电线路的功率）",
          },
          {
            text: "统计与分析（实行自动判断优先级，实现平衡分析，量测平衡率的自动统计）",
          },
          {
            text: "自动发电控制（实现电压调整和频率控制，能量变换装置的功率输出的调节，储能装置的管理）",
          },
          {
            text: "状态估计（实时地给出公共连接点电网参数，各母线电压，各线路、变压器等支路的潮流，各母线的负荷和各微电源的输出特性参数）",
          },
          {
            text: "能量供需预测（对微电源出力进行超短期、短期和中长期的预测）",
          },
          {
            text: "调度员潮流（可设定操作，改变运行方式）",
          },
        ],
    };
  },
};
</script>

<style scoped>
.bottomCss {
  width: 100%;
  height: 100%;
  background: #f2f5f8;
  display: flex;
  justify-content: center;
}
.xiangqing {
  width: 1314px;
  /* height: 1404px; */
  margin-top: 64px;
  margin-bottom: 88px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 38px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  /* line-height: 0px; */
  margin-top: 54px;
  margin-bottom: 36px;
}
.jieshao {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 38px;
  width: 1186px;
}
.xiaobiaoqian {
  display: flex;
  margin-bottom: 36px;
  margin-top: 48px;
  margin-left: -78.5%;
}
.xiaobiaoqian9 {
  display: flex;
  margin-bottom: 36px;
  margin-top: 48px;
  margin-left: -67%;
}
li::before {
  content: "";
  display: flex;
  width: 9px;
  height: 9px;
  background: #0940fd;
  border-radius: 50%;
  margin: 1%;
}
</style>